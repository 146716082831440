import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

export function useUserProfile() {
  const queryClient = useQueryClient();

  const query = useQuery(
    ["userProfile"],
    async () => {
      // Don't fetch user profile on auth page
      if (typeof window !== 'undefined' && window.location.pathname.includes('/auth')) {
        return null;
      }
      console.log("Fetching user profile");
      const { data } = await axios.get("/api/current-user");
      return data;
    },
    {
      staleTime: 120 * 60 * 1000, // 2hrs
      cacheTime: 120 * 60 * 1000, // 2hrs
      onError: (error) => {
        console.error("Error fetching user profile:", error);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const refetchUserProfile = () => {
    query.refetch();
  };

  const invalidateUserProfile = () => {
    queryClient.invalidateQueries(["userProfile"]);
  };
  const removeUserProfile = () => {
    queryClient.removeQueries(["userProfile"]);
  };

  return {
    ...query,
    refetchUserProfile,
    invalidateUserProfile,
    removeUserProfile,
  };
}

export function useLogout() {
  const queryClient = useQueryClient();

  const logoutQuery = () => {
    // Remove the queries for "userProfile" from the cache
    queryClient.removeQueries(["userProfile"]);
  };

  return {  logoutQuery };
}