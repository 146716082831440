import { useContext } from "react";
import { Context } from "../../../context";
import Image from "next/image";
import LayoutFillImg from "../LayoutFillImg/LayoutFillImg";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { BiMenuAltRight, BiUserPlus } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import { UseHeaderContext } from "../../../context/HeaderContext";
import { UseWindowContext } from "../../../context/WindowContext";
// import logo from "../../../public/logo.png";
// import flora_logo from "../../../public/flora_logo.png";
import outdoorbuilderslogo from "../../../public/outdoorbuilderslogo-removebg-preview.png";
import Container from "../container/Container";
import DashboardProfile from "../dashboardProfile/DashboardProfile";
import SideNavigation from "../../Dashboard/SideNavigation/SideNavigation";
import Button from "../button/Button";
import styles from "./header.module.scss";
import { useUserProfile } from "../../../hooks/auth";
import { useSelectedBusiness, useUserBusinesses } from "../../../hooks/business";
import ChatHeader from "@/components/Chat/chatHeader/ChatHeader";
import { setCookie, getCookie, hasCookie, deleteCookie } from "cookies-next";

export default function Header() {
  const {
    setMenuTopHeight,
    setHeaderHeight,
    hContainerMWidth,
    specialNavPath,
  } = UseHeaderContext();
  const [isScroll, setIsScroll] = useState(false);
  const router = useRouter();
  const headerRef = useRef(null);
  const menuTopRef = useRef(null);
  const overlayRef = useRef(null);
  const menusWrapperRef = useRef(null);
  const { windowWidth } = UseWindowContext();

  const { data: user, isLoading: userLoading } = useUserProfile();
  const { selectedBusiness, setSelectedBusiness } = useSelectedBusiness();
  const { businesses, isLoading: businessesLoading } = useUserBusinesses();
  const [businessOptions, setBusinessOptions] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);

  console.log("BUSINESSES", businesses)

  // Transform businesses data when it changes
  useEffect(() => {
    if (!user || !businesses || userLoading || businessesLoading) return;

    const transformedBusinesses = [
      {
        value: "",
        label: "All Business",
        role: "all"
      },
      ...businesses.map(business => {
        const isAdmin = business.createdBy === user._id || 
                     (Array.isArray(business.admins) && business.admins.includes(user._id));
        return {
          value: business._id,
          label: business.name,
          role: isAdmin ? 'admin' : 'employee',
          slug: isAdmin ? `/business/project-management` : `/business/employee-project-management`,
          ...business
        };
      })
    ];

    setBusinessOptions(transformedBusinesses);

    // Set default business if none selected
    if (!selectedBusiness && transformedBusinesses.length > 0) {
      handleBusinessSelect(transformedBusinesses[0]);
    }
  }, [user, businesses, userLoading, businessesLoading]);

  // Handle business selection
  const handleBusinessSelect = (business) => {
    setSelectedBusiness(business);
    setCookie('business', JSON.stringify(business));
    setIsOpen(false);
    // Filter projects based on selected business
    if (business.value === "") {
      setFilteredProjects(projects); // Show all projects
    } else {
      setFilteredProjects(projects.filter(project => project.business === business._id));
    }
  };

  // Handle project selection
  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    
    // Find and select the corresponding business
    if (project && businesses) {
      const correspondingBusiness = businesses.find(b => b._id === project.business);
      if (correspondingBusiness) {
        handleBusinessSelect(correspondingBusiness);
      }
    }
  };

  useEffect(() => {
    if (!user || !businesses || userLoading || businessesLoading) return;
    
    // If there's no selected business, set it to "All Business"
    if (!selectedBusiness && businesses.length > 0) {
      handleBusinessSelect(businesses[0]); // First item is "All Business"
    }
  }, [user, businesses, userLoading, businessesLoading]);

  // Update filtered projects when projects or selected business changes
  useEffect(() => {
    if (selectedBusiness) {
      if (selectedBusiness.value === "") {
        setFilteredProjects(projects);
      } else {
        setFilteredProjects(projects.filter(project => project.business === selectedBusiness._id));
      }
    }
  }, [selectedBusiness, projects]);

  // get header height
  const getHeaderHeight = useCallback(() => {
    setHeaderHeight(headerRef.current.getBoundingClientRect().height);
    windowWidth < 992 &&
      setMenuTopHeight(menuTopRef.current.getBoundingClientRect().height);
  }, [setHeaderHeight, setMenuTopHeight, windowWidth]);
  // set header height
  useEffect(() => {
    getHeaderHeight();
  }, [getHeaderHeight]);

  // resize window
  const onResizeWindow = useCallback(() => {
    getHeaderHeight();
    if (router.pathname !== "/user/chat") {
      windowWidth < 992 ? menuToggler() : menuToggler("OPEN", "noOverlay");
    } else {
      return;
    }
  }, [getHeaderHeight, windowWidth, router.pathname]);
  // invoked
  useEffect(() => {
    onResizeWindow();
    // invoked window width --resize
    window.addEventListener("resize", onResizeWindow);
    return () => window.removeEventListener("resize", onResizeWindow);
  }, [onResizeWindow]);

  // active menu tab
  function menuActiveTab(menu) {
    if (router.asPath === menu) {
      return styles.active;
    } else if (router.asPath === "/" && menu.toLowerCase() === "home") {
      return styles.active;
    } else if (
      router.asPath ===
      "/" + menu.toLowerCase().split(" ").join("-")
    ) {
      return styles.active;
    } else if (router.asPath == menu.split("/").includes("business")) {
      return styles.active;
    }
  }

  useEffect(() => {
    // on scroll
    function onScroll() {
      window.scrollY > 80 ? setIsScroll(true) : setIsScroll(false);
    }
    // invoked on scroll
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // on menu click
  const menuToggler = (type, overType) => {
    const overlay = overlayRef.current;
    const menu = menusWrapperRef.current;

    if (type === "OPEN") {
      if (overType !== "noOverlay") {
        overlay.style.opacity = 1;
        overlay.style.pointerEvents = "all";
      }
      menu.style.transform = `translateX(0)`;
    } else {
      menu.style.transform = `translateX(100%)`;
      overlay.style.opacity = 0;
      overlay.style.pointerEvents = "none";
    }
  };

  const routeChangeHandler = useCallback(() => {
    windowWidth < 992 && menuToggler();
  }, [windowWidth]);

  // router change
  useEffect(() => {
    router.events.on("routeChangeComplete", routeChangeHandler);
    return () => router.events.off("routeChangeComplete", routeChangeHandler);
  }, [routeChangeHandler, router.events]);

  const [isOpen, setIsOpen] = useState(false);
  const [isProjectOpen, setProjectIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
    setProjectIsOpen(false);
  };

  const toggleProjectDropdown = () => {
    setProjectIsOpen((prev) => !prev);
    setIsOpen(false);
  };

  return (
    <>
      {router.pathname === specialNavPath && specialNavPath.includes("chat") ? (
        <ChatHeader />
      ) : (
        <header
          className={`${styles.header}${isScroll ? " " + styles.scroll : ""}`}
          ref={headerRef}
        >
          <Container
            style={
              router.pathname === specialNavPath
                ? { maxWidth: hContainerMWidth }
                : {}
            }
          >
            <nav className={styles.nav}>
              <div className={styles.logo}>
                <Link href="/">
                  <Image
                    src={outdoorbuilderslogo}
                    alt="Outdoor builders AI logo"
                    width={100}
                    height={38}
                  />
                </Link>
              </div>
              {windowWidth < 992 && (
                <button
                  type="button"
                  className={styles.menuBar}
                  onClick={() => menuToggler("OPEN")}
                >
                  <BiMenuAltRight />
                </button>
              )}
              <div
                className={styles.overlay}
                ref={overlayRef}
                onClick={menuToggler}
              />
              <div className={styles.menusWrapper} ref={menusWrapperRef}>
                {windowWidth < 992 && (
                  <div className={styles.menuTopBar} ref={menuTopRef}>
                    <div className={styles.logo}>
                      <Link href="/">
                        <Image
                          src={outdoorbuilderslogo}
                          alt="Outdoor builders AI logo"
                          width={100}
                          height={38}
                        />
                      </Link>
                    </div>
                    <button className={styles.menuClose} onClick={menuToggler}>
                      <MdOutlineClose />
                    </button>
                  </div>
                )}
                {router.pathname === specialNavPath &&
                specialNavPath == "/dashboard" &&
                windowWidth < 992 ? (
                  <SideNavigation isTitle={false} className="between" />
                ) : (
                  <ul className={styles.menus}>
                    <>
                      {!user &&
                        !router.pathname.split("/").includes("business") ===
                          false && (
                          <li>
                            <Link href="/free-trial">
                              <span className={menuActiveTab("/free-trail")}>
                                Free Trial
                              </span>
                            </Link>
                          </li>
                        )}

                      {user && (
                        <>
                          <li>
                            <div className={styles.dropdown}>
                              {router.pathname
                                .split("/")
                                .includes("business") && (
                                <button
                                  className={styles.dropdownToggle}
                                  onClick={toggleDropdown}
                                >
                                  {selectedBusiness
                                    ? selectedBusiness.label
                                    : "Select Business"}
                                  <span className={styles.arrow}>&#9660;</span>
                                </button>
                              )}
                              {isOpen && (
                                <ul className={styles.dropdownMenu}>
                                  {businessOptions.map((business) => (
                                    <li
                                      key={business.value || 'all'}
                                      className={styles.dropdownItem}
                                      onClick={() => handleBusinessSelect(business)}
                                    >
                                     
                                        <span>{business.label}</span>
                                     
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </li>
                          {router.pathname.split("/").includes("business") && (
                            <>
                              {filteredProjects.length > 0 && (
                                <li>
                                  <div className={styles.dropdown}>
                                    <button
                                      className={styles.dropdownToggle}
                                      onClick={toggleProjectDropdown}
                                    >
                                      {selectedProject
                                        ? selectedProject.name
                                        : "Select Project"}
                                      <span className={styles.arrow}>
                                        &#9660;
                                      </span>
                                    </button>
                                    {isProjectOpen && (
                                      <ul className={styles.dropdownMenu}>
                                        {filteredProjects.map((project) => (
                                          <li
                                            key={project._id}
                                            className={styles.dropdownItem}
                                            onClick={() =>
                                              handleProjectSelect(project)
                                            }
                                          >
                                            <Link href={`/project/${project._id}`}>
                                              <span>{project.name}</span>
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                </li>
                              )}
                            </>
                          )}

                          <li>
                            <Link href="/" className={menuActiveTab("home")}>
                              <span>Home</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              href="/user/projects"
                              className={menuActiveTab("user/projects")}
                            >
                              <span>Projects</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              href="/user/chat"
                              className={menuActiveTab("user/chat")}
                            >
                              <span>Messages</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              href="/gallery"
                              className={menuActiveTab("gallery")}
                            >
                              <span>Gallery</span>
                            </Link>
                          </li>

                          <Link href="/credits">
                            <span>
                              <strong>
                                {user.remainingImagegenerationCredits} credits
                              </strong>
                            </span>
                          </Link>
                        </>
                      )}
                      {(router.pathname || router.asPath) == specialNavPath ||
                      user ? (
                        <li className={styles.dashboardProfileLink}>
                          <DashboardProfile />
                        </li>
                      ) : (
                        <ul className={styles.buttonsDiv}>
                          <li className={styles.menusBtnLink}>
                            <Link href="/business">
                              <div className={menuActiveTab("/auth/business")}>
                                <Button
                                  variant={"secondary"}
                                  // onClick={() => router.push("/auth/business")}
                                >
                                  Business
                                </Button>
                              </div>
                            </Link>
                          </li>
                          <li className={styles.menusBtnLink}>
                            <Link href="/auth">
                              <div className={menuActiveTab("/auth")}>
                                <Button
                                  variant={"cta"}
                                  // onClick={() => router.push("/auth")}
                                >
                                  Login
                                </Button>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </>
                  </ul>
                )}
              </div>
            </nav>{" "}
          </Container>
        </header>
      )}
    </>
  );
}
