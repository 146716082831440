import { useQuery, useQueryClient } from '@tanstack/react-query';
import { setCookie, getCookie, hasCookie } from 'cookies-next';
import axios from 'axios';
import { useMemo } from 'react';
import { useUserProfile } from './auth'; // assuming useUserProfile is in a separate file

export const SELECTED_BUSINESS_KEY = 'selectedBusiness';
export const USER_BUSINESSES_KEY = 'userBusinesses';

export const useSelectedBusiness = () => {
  const queryClient = useQueryClient();

  const { data: selectedBusiness } = useQuery({
    queryKey: [SELECTED_BUSINESS_KEY],
    initialData: hasCookie("business") ? JSON.parse(getCookie("business")) : null,
    staleTime: Infinity,
  });

  const setSelectedBusiness = (business) => {
    setCookie("business", JSON.stringify(business));
    queryClient.setQueryData([SELECTED_BUSINESS_KEY], business);
  };

  return { selectedBusiness, setSelectedBusiness };
};

export const useUserBusinesses = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [USER_BUSINESSES_KEY],
    queryFn: async () => {
      try {
        const response = await axios.get('/api/get-all-user-businesses');
        console.log("HOOK BUSINESSES", response.data);
        // Handle both possible data structures consistently
        return response.data?.data || response.data || [];
      } catch (err) {
        console.error('Error fetching businesses:', err);
        return [];
      }
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  });

  return { 
    businesses: data || [],
    isLoading,
    error 
  };
};